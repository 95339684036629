import APIService from "./api";

/** This class will include all services defined in unit-service */
export default class ReportService extends APIService {
  service = "report-service";
  async generateReport(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "generateReport" },
      data,
      options
    );
  }
  async createReport(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "createReport" },
      data,
      options
    );
  }
  async updateReport(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "updateReport" },
      data,
      options
    );
  }
  async saveReport(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "saveReport" },
      data,
      options
    );
  }
  async deleteReport(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "deleteReport" },
      data,
      options
    );
  }
  async getAllCreatedReport(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAllCreatedReport" },
      data,
      options
    );
  }
  async getReportTypeDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getReportTypeDetails" },
      data,
      options
    );
  }
  async getAllReportCategories(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAllReportCategories" },
      data,
      options
    );
  }
  async getCreatedReport(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getCreatedReport" },
      data,
      options
    );
  }
  async getConsumerChartData(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: this.service,
        method: "getConsumerChartData"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getUnitDeviceSensors(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: this.service,
        method: "getUnitDeviceSensors"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getAllWidgets(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: this.service,
        method: "getAllWidgets"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getWidgetsData(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: this.service,
        method: "getWidgetsData"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async createWidgets(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: this.service,
        method: "createWidgets"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async updateWidgets(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: this.service,
        method: "updateWidgets"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getSavedWidgets(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: this.service,
        method: "getSavedWidgets"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async reorderingWidgets(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: this.service,
        method: "reorderingWidgets"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async deleteWidgets(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: this.service,
        method: "deleteWidgets"
      },
      data,
      { ...options, show_loader: false }
    );
  }
  async getOneWidget(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      {
        service: this.service,
        method: "getOneWidget"
      },
      data,
      { ...options, show_loader: false }
    );
  }
}
