<template>
  <div
    class="circle-progress"
    :style="{ width: size + 'px', height: size + 'px' }"
  >
    <svg :width="size" :height="size">
      <circle class="bg-circle" :cx="radius" :cy="radius" :r="radius" />
      <circle
        class="progress-circle"
        :cx="radius"
        :cy="radius"
        :r="radius"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="progressOffset"
      />
    </svg>
    <div class="progress-label">{{ progress }}%</div>
  </div>
</template>

<script>
import { BProgress } from "bootstrap-vue";
export default {
  components: { BProgress },

  props: {
    size: {
      type: Number,
      default: 200
    },
    progress: {
      type: Number,
      required: true
    }
  },
  computed: {
    radius() {
      return this.size / 2;
    },
    circumference() {
      return 2 * Math.PI * this.radius;
    },
    progressOffset() {
      return this.circumference * (1 - this.progress / 100);
    }
  }
};
</script>

<style lang="scss" scoped>
.circle-progress {
  position: relative;
  svg {
    overflow: visible;
  }
}

.circle-progress svg {
  transform: rotate(-90deg);
}

.circle-progress .bg-circle {
  fill: none;
  stroke: #e6e6e6; /* Background color */
  stroke-width: 20;
}

.circle-progress .progress-circle {
  fill: none;
  stroke: var(--primary); /* Progress color */
  stroke-width: 20;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.3s;

  margin: 0 auto;
  align-items: center;
  left: 0;
  position: absolute;
  top: 50%;
  display: flex;
  right: 0;
  justify-content: center;
}
.circle-progress {
  margin: 0 auto;
  align-items: center;
  left: 0;
  position: absolute;
  top: 30%;
  display: flex;
  right: 0;
  justify-content: center;
}

.circle-progress .progress-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 51px;
  font-weight: bold;
  color: var(--primary);
}
</style>
