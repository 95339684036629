<template>
  <div
    id="app"
    :class="isOpen ? 'is-report-filter-open' : 'is-report-filter-close'"
  >
    <b-card class="progressC" v-if="isCardLoading">
      <span class="progress-main">
        <span
          :style="isLoading && !reportFailed ? 'opacity: 23%' : 'opacity: 40%'"
        >
          <ReportHome class="img-h-w"></ReportHome
        ></span>
        <!-- <b-img style="opacity: 23%" :src="report_img_url" class="img-h-w" /> -->
        <circle-progress
          v-if="isLoading && (!reportFailed || report.status === 'FAILED')"
          :progress="progress"
        ></circle-progress>
        <div
          class="progress-child"
          v-if="isLoading && (!reportFailed || report.status === 'FAILED')"
        >
          <div>{{ $t("ReportsData.ReportIsGenerating") }}</div>
          <div class="mt-1">{{ $t("ReportsData.PleaseWait") }}</div>
        </div>

        <AlertTriangleIcon
          class="report-error-icon"
          v-if="(report.status === 'FAILED' || reportFailed) && !isLoading"
        />
        <span
          class="progress-child-error"
          v-if="(report.status === 'FAILED' || reportFailed) && !isLoading"
        >
          <div>
            {{ $t("ReportsData.ReportIsGeneratingFailed") }}
          </div>
        </span>
      </span>
    </b-card>
    <b-card v-if="isReportEnable">
      <div class="d-flex justify-content-between align-items-center mb-1">
        <h4 class="mb-0">
          <!-- {{ $t("report.report") }} -->
        </h4>
        <div class="d-flex justify-content-center align-items-center">
          <div class="mr-1">
            <VideoReportIcon
              v-b-tooltip.hover.v-primary
              :title="$t('ReportsData.VIDEO')"
              v-if="getFileUrl('VID')"
              @click="downloadReport('VID')"
              class="report-icon"
            />
            <span @click="downloadReport('EXCEL')">
              <ExlReportIcon
                class="report-icon"
                v-b-tooltip.hover.v-primary
                :title="$t('ReportsData.EXL')"
                v-if="getFileUrl('EXCEL')"
            /></span>
            <ChartReportIcon
              v-b-tooltip.hover.v-primary
              :title="$t('ReportsData.CHART')"
              class="report-icon"
              v-if="getFileUrl('CHART')"
              @click="downloadReport('CHART')"
            />
            <span @click="downloadReport('PDF')">
              <PdfReportIcon
                v-b-tooltip.hover.v-primary
                :title="$t('ReportsData.PDF')"
                class="report-icon"
                v-if="getFileUrl('PDF')"
            /></span>
            <DocReportIcon
              v-b-tooltip.hover.v-primary
              :title="$t('ReportsData.DOC')"
              class="report-icon"
              v-if="getFileUrl('DOC')"
              @click="downloadReport('DOC')"
            />
          </div>
          <b-button
            variant="primary"
            class="btn-icon d-flex justify-content-center align-items-center"
            @click="showSaveModal()"
          >
            <feather-icon icon="SaveIcon" size="18" />
            <span class="ml-10">{{ $t("ReportsData.Save") }}</span>
          </b-button>
          <b-button
            variant="primary"
            class="btn-icon d-flex justify-content-center align-items-center ml-10"
            @click="
              (e) => {
                $router.push({ name: 'report-list' });
              }
            "
          >
            <feather-icon icon="ArrowLeftIcon" size="18" />
            <span class="ml-10">{{ $t("ReportsData.Back") }}</span>
          </b-button>
        </div>
      </div>

      <div
        class="pdf-card"
        v-if="fileUrl || fileUrlPageCount"
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        "
      >
        <Loader :show="pdfLoading" />
        <!-- <embed class="w-100 h-100" width="100%" :src="fileUrl" /> -->
        <div
          class="w-100 h-100"
          style="overflow: auto"
          v-if="fileUrlPageCount > 1"
        >
          <pdf
            v-for="page in fileUrlPageCount - 1"
            :key="page"
            :src="fileUrl"
            :page="page + 1"
          />
        </div>
        <div
          v-else-if="
            (!fileUrlPageCount || fileUrlPageCount == 1) && !pdfLoading
          "
        >
          {{ $t("ReportsData.NoReportsFound") }}
        </div>
      </div>
    </b-card>
    <SaveReportModal :reportData="report"> </SaveReportModal>
  </div>
</template>

<script>
import CircleProgress from "./CircleProgress.vue";
import { BCard, BImg, BButton, BModal, VBTooltip } from "bootstrap-vue";
import ChartReportIcon from "@/assets/images/new-icon/chart-report.svg";
import DocReportIcon from "@/assets/images/new-icon/doc.svg";
import AlertTriangleIcon from "@/assets/images/new-icon/alert-triangle.svg";
import ExlReportIcon from "@/assets/images/new-icon/exl.svg";
import PdfReportIcon from "@/assets/images/new-icon/pdf.svg";
import VideoReportIcon from "@/assets/images/new-icon/video-report.svg";
import ReportHome from "@/assets/images/new-icon/report-home.svg";
import SaveReportModal from "./SaveReportModal.vue";
import SocketIOService from "@/libs/socket/sockets";
import EventBus from "@/EventBus";
import ReportService from "@/libs/api/report-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { DownloadIcon } from "vue-feather-icons";
import store from "@/store";
import pdf from "vue-pdf";
import Loader from "@/layouts/components/Loader.vue";

export default {
  components: {
    CircleProgress,
    BCard,
    BImg,
    BButton,
    BModal,
    ChartReportIcon,
    ExlReportIcon,
    AlertTriangleIcon,
    PdfReportIcon,
    VideoReportIcon,
    DocReportIcon,
    SaveReportModal,
    VBTooltip,
    pdf,
    Loader,
    ReportHome
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  created() {
    EventBus.$on("add-update-report", this.addUpdateReport);
    EventBus.$on("report-socket", this.integrateSocket);
    EventBus.$on("is-customizer-open", this.updateCustomClass);
  },
  data() {
    return {
      progress: 0,
      isReportEnable: false,
      isLoading: false,
      isCardLoading: true,
      isOpen: true,
      reportFailed: false,
      pdfLoading: false,
      fileUrl: "",
      fileUrlPageCount: undefined,
      isCreateAPICall: false,
      report_img_url: require("@/assets/images/pages/report-bg.png"),
      report: {},
      showModal: false // Controls the visibility of the modal
    };
  },
  mounted() {
    // Simulate progress increment
    if (this.$route.name === "edit-report") {
      // this.getCreatedReportAPI();
    }
  },
  watch: {
    $route(to, from) {
      if (from.name === "select-unit" && to.name == "edit-report") {
        this.integrateSocket(this.report);
      }
      if (from.name === "edit-unit" && to.name == "edit-report") {
        this.integrateSocket(this.report);
      }
    }
  },
  methods: {
    showSaveModal() {
      this.$bvModal.show("modal-sm-consumer-invitations");
    },
    onIframeLoaded() {
      console.error("load URL");
    },
    onIframeError() {
      console.error("Failed to load URL");
    },
    updateCustomClass(open) {
      this.isOpen = open;
    },
    getFileUrl(file) {
      if (this.report.files && this.report.files.length) {
        const data = this.report.files.find(
          (e) => file === e.extension && e.file_id
        );
        return data && data.file_id ? data.file_id : "";
      } else {
        return "";
      }
    },
    addUpdateReport(report) {
      if (this.isCreateAPICall) {
        return;
      }
      this.isCreateAPICall = true;
      this.report = report;
      this.isLoading = true;
      this.callCreateReport();
    },
    async callCreateReport() {
      // if (!this.isEditable) {
      //   this.$router.push({ name: "report-list" });
      //   return;
      // }
      try {
        const me = this;
        this.progress = 0;
        const result = this.checkValidation(this.report);
        if (result.success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: result.field,
              text: result.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });

          return;
        }
        this.reportFailed = false;
        this.show = true;
        let response;
        this.isLoading = true;
        this.isCardLoading = true;
        this.isReportEnable = false;
        this.report.entities = this.report.entities.filter((e) => e.entity_id);
        if (!this.report.entities || !this.report.entities.length) {
          this.report.is_all_units = true;
        } else {
          this.report.is_all_units = false;
        }

        if (this.report.id) {
          const UpdatedData = {
            id: this.report.id,
            report_name: this.report.report_name,
            parameters: this.report.parameters,
            processor: this.report.processor,
            account_id: this.report.account_id,
            is_all_units: this.report.is_all_units,
            entities: this.report.entities,
            labels: this.report.labels,
            report_type: this.report.report_type,
            sensors: this.report.sensors
          };
          response = await new ReportService().updateReport(UpdatedData);
        } else {
          response = await new ReportService().createReport(this.report);
        }

        this.isCreateAPICall = false;

        if (response && response.data) {
          // this.$router.push({
          //   name: "report-list"
          // });

          this.report.id = this.report.id
            ? this.report.id
            : response.data.report_id;
          this.report.status = response.data.status.type;

          this.integrateSocket(this.report);
          this.$router.push({
            name: "edit-report",
            params: {
              id: this.report.id
            }
          });
        } else if (response && response.error) {
          this.reportFailed = true;
          if (response.error.code === "POLY_ERR_REPORT_IN_PROGRESS") {
          } else {
            store.commit("report/SET_ENABLE_GENERATE", false);
            this.report.status == "FAILED";
          }

          if (response.error.message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.error.title,
                text: response.error.message,
                icon: "InfoIcon",
                variant: "danger"
              }
            });
          } else {
          }
        }
      } catch (err) {
        store.commit("report/SET_ENABLE_GENERATE", false);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    checkValidation(report) {
      // if (!report.report_name) {
      //   return {
      //     success: true,
      //     field: this.$t("ReportsData.tableColumns.reportName"),
      //     message: this.$t("ReportsData.NameRequired")
      //   };
      // }
      // if (report.report_name.length > 20) {
      //   return {
      //     success: true,
      //     field: this.$t("ReportsData.tableColumns.reportName"),
      //     message: this.$t("ReportsData.CharacterGreater")
      //   };
      // }
      for (let i = 0; i < report.parameters.length; i++) {
        if (report.parameters[i].name == "date_range") {
          if (!report.parameters[i].value) {
            return {
              success: true,
              field: this.$t("ReportsData.DateRange"),
              message: this.$t("ReportsData.DateRequired")
            };
          }
        }
        if (report.parameters[i].name == "multiple_shifts") {
          if (!report.parameters[i].value) {
            return {
              success: true,
              field: this.$t("ReportsData.ShiftRequired"),
              message: this.$t("ReportsData.ShiftRequiredMsg")
            };
          }
        }
      }
      return {
        success: false
      };
    },
    async integrateSocket(report) {
      try {
        this.report = report;
        if (this.report && this.report.progress < 100) {
          this.progress = this.report.progress;
        }

        if (report.status == "PROCESSING" || report.status == "NOT_STARTED") {
          this.reportFailed = false;
          if (this.socket) {
            this.socket.disconnect();
            this.socket = null;
          }
          this.socket = new SocketIOService();
          await this.socket.setupSocketConnection();
          this.isLoading = true;
          this.socket.subscribeEventWithFunction(
            `REPORT_PROGRESS${this.report.id}`,
            (data) => {
              this.report.status = data.status.status;
              this.report.error = data.status.error;
              this.report.files = data.files;
              this.progress = data.progress;
              if (data.status && data.status.status === "FAILED") {
                this.reportFailed = true;
                this.report.status === "FAILED";
                store.commit("report/SET_ENABLE_GENERATE", true);
              } else if (Number(data.progress) >= 100) {
                // console.log("socket data", data);
                // this.report.formats = data.files;
                this.isCardLoading = false;
                this.isReportEnable = true;
                store.commit("report/SET_ENABLE_GENERATE", false);
                const file = data.files.find(
                  (item) => item.extension === "PDF"
                );
                this.report.files = data.files;
                this.fileUrl = file?.file_id;
                this.pdfLoading = true;
                this.fileUrlPageCount = undefined;
                this.fileUrl = pdf.createLoadingTask(this.fileUrl);
                this.fileUrl.promise
                  .then((pdf) => {
                    this.pdfLoading = false;
                    this.fileUrlPageCount = pdf.numPages;
                  })
                  .catch(() => {
                    this.pdfLoading = false;
                    this.reportFailed = true;
                  });
              } else {
                store.commit("report/SET_ENABLE_GENERATE", true);
              }
            }
          );
        } else if (report.status == "FAILED") {
          this.reportFailed = true;
          store.commit("report/SET_ENABLE_GENERATE", false);
        } else if (report.status == "COMPLETED") {
          store.commit("report/SET_ENABLE_GENERATE", false);
          this.isCardLoading = false;
          this.isReportEnable = true;
          const file = report.files.find((item) => item.extension === "PDF");
          this.pdfLoading = true;
          this.fileUrl = file?.file_id;
          this.fileUrlPageCount = undefined;
          this.fileUrl = pdf.createLoadingTask(this.fileUrl);
          this.fileUrl.promise
            .then((pdf) => {
              this.pdfLoading = false;
              this.fileUrlPageCount = pdf.numPages;
            })
            .catch(() => {
              this.pdfLoading = false;
              this.reportFailed = true;
            });
        }
      } catch (error) {}
    },
    async downloadReport(type) {
      try {
        const url = this.getFileUrl(type);
        if (url) {
          const report_url = url;
          const link = document.createElement("a");

          link.href = report_url;
          link.target = "_blank";

          link.setAttribute("download", "report");

          document.body.appendChild(link);

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  },
  beforeDestroy() {
    EventBus.$off("add-update-report", this.addUpdateReport);
    EventBus.$off("report-socket", this.integrateSocket);
    EventBus.$off("is-customizer-open", this.updateCustomClass);
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }
};
</script>

<style scoped lang="scss">
.progressC {
  .card-body {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
.img-h-w {
  width: 100%;
  //height: 40vh;
}
.pdf-card {
  width: 100%;
  height: calc(100vh - 220px);
  border: 0.5cap solid gray;
}
.report-icon {
  width: 30px;
  cursor: pointer;
}
.is-report-filter-open {
  &#app {
    width: calc(100% - 400px);
  }
}
.report-error-icon {
  path {
    fill: var(--danger);
  }
  position: absolute;
  top: 100px;
  right: 0;
  left: 0;
  margin: 0 auto;
}
.progress-main {
  position: relative;
  min-width: 250px;
  .progress-child {
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
    top: 325px;
    color: var(--primary);
    font-size: 20px;
    font-weight: 600;
    line-height: 18.8px;
    letter-spacing: 0.14px;
  }
  .progress-child-error {
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
    top: 250px;
    color: var(--danger);
    font-size: 20px;
    font-weight: 600;
    line-height: 18.8px;
    letter-spacing: 0.14px;
  }
}
</style>
