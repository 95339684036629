var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-sm-consumer-invitations","centered":"","modal-class":"no-header-modal"}},[_c('b-card-text',[_c('div',{staticClass:"new-invite-modal-title"},[_vm._v(" "+_vm._s(_vm.$t("ReportsData.SaveReport"))+" ")]),_c('div',{staticClass:"you-invite"},[_vm._v(_vm._s(_vm.$t("ReportsData.SaveReportDesc")))]),_c('validation-observer',{ref:"signupValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mtt-37"},[_c('b-form-group',{attrs:{"label":_vm.$t('ReportsData.ReportName')}},[_c('validation-provider',{attrs:{"name":"Role","rules":{
              required: true,
              min: _vm.constants.MIN_REPORT_NAME,
              max: _vm.constants.MAX_REPORT_NAME
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.report.report_name),callback:function ($$v) {_vm.$set(_vm.report, "report_name", $$v)},expression:"report.report_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1),(
            (_vm.report.report_id &&
              _vm.checkAbility({
                action: _vm.constants.PERMISSIONS_ACTION.READ,
                subject: _vm.constants.PERMISSIONS_MODEL.LABELS
              })) ||
            _vm.checkAbility({
              action: _vm.constants.PERMISSIONS_ACTION.ADD,
              subject: _vm.constants.PERMISSIONS_MODEL.LABELS
            })
          )?_c('b-form-group',{staticClass:"tag-select-disable",attrs:{"label":_vm.$t('unit.Labels')}},[_c('TagMultiSelect',{model:{value:(_vm.report.labels),callback:function ($$v) {_vm.$set(_vm.report, "labels", $$v)},expression:"report.labels"}})],1):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center mb-64 mtt-50"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary","disabled":invalid ||
              !_vm.reportData ||
              !_vm.reportData.id ||
              _vm.$route.name === 'view-report' ||
              _vm.isShow},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("ReportsData.Save"))+" ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }