<template>
  <b-modal
    id="modal-sm-consumer-invitations"
    centered
    modal-class="no-header-modal"
  >
    <b-card-text>
      <div class="new-invite-modal-title">
        {{ $t("ReportsData.SaveReport") }}
      </div>
      <div class="you-invite">{{ $t("ReportsData.SaveReportDesc") }}</div>
      <validation-observer ref="signupValidation" #default="{ invalid }">
        <b-form class="mtt-37">
          <b-form-group :label="$t('ReportsData.ReportName')">
            <validation-provider
              #default="{ errors }"
              name="Role"
              :rules="{
                required: true,
                min: constants.MIN_REPORT_NAME,
                max: constants.MAX_REPORT_NAME
              }"
            >
              <b-form-input id="name" v-model="report.report_name" />
              <small class="text-danger">{{
                errors[0] ? handleError(errors[0]) : ""
              }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('unit.Labels')"
            class="tag-select-disable"
            v-if="
              (report.report_id &&
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.READ,
                  subject: constants.PERMISSIONS_MODEL.LABELS
                })) ||
              checkAbility({
                action: constants.PERMISSIONS_ACTION.ADD,
                subject: constants.PERMISSIONS_MODEL.LABELS
              })
            "
          >
            <TagMultiSelect v-model="report.labels" />
          </b-form-group>
          <div class="d-flex justify-content-center mb-64 mtt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="
                invalid ||
                !reportData ||
                !reportData.id ||
                $route.name === 'view-report' ||
                isShow
              "
              @click="save()"
            >
              {{ $t("ReportsData.Save") }}
            </b-button>
          </div>
        </b-form></validation-observer
      >
    </b-card-text>
  </b-modal>
</template>
<script>
import {
  BCard,
  BFormInput,
  BButton,
  BFormGroup,
  BModal,
  BCardText,
  BForm
} from "bootstrap-vue";
import constants from "@/utils/constants";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ReportService from "@/libs/api/report-service";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min, max } from "@validations";

import TagMultiSelect from "@/layouts/components/TagMultiSelect.vue";

export default {
  components: {
    BCard,
    BFormInput,
    BButton,
    BFormGroup,
    BModal,
    BCardText,
    BForm,
    TagMultiSelect,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      report: {
        report_name: "",
        labels: [],
        report_id: ""
      },
      hasErr: "",
      isShow: false
    };
  },
  directives: {
    // "b-tooltip": VBTooltip,
    Ripple
  },
  props: ["reportData"],
  watch: {
    reportData() {
      this.report.report_name =
        this.reportData.name || this.reportData.report_name;
      this.report.labels = this.reportData.labels;
      this.report.report_id = this.reportData.id;
    }
  },
  methods: {
    async save() {
      // if (!this.isEditable) {
      //   this.$router.push({ name: "report-list" });
      //   return;
      // }
      try {
        this.report.report_id = this.reportData.id;
        this.isShow = true;
        const response = await new ReportService().saveReport(this.report);

        if (response && response.data) {
          this.isShow = false;
          // console.log(this.report);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("ReportsData.SavedSuccessFully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.close();

          this.$router.push({ name: "report-list" });
        } else if (response && response.error && response.error.message) {
          this.isShow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.isShow = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    close() {
      this.$bvModal.hide("modal-sm-consumer-invitations");
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.you-invite {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  margin-top: 14px;
}
.new-invite-modal-title {
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: $headings-color;
}
</style>
