import { io } from "socket.io-client";
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store";

export default class SocketIOService {
  socket;
  constructor() {}

  async setupSocketConnection() {
    const userToken = localStorage.getItem(
      useJwt.jwtConfig.storageTokenKeyName
    );
    const account = localStorage.getItem("USER_ACCOUNT_ID");
    if (userToken) {
      this.socket = await io(
        `${process.env.VUE_APP_SOCKET_URL}?token=${userToken}&account=${account}`
      );
    }
  }
  disconnect() {
    this.socket.disconnect();
  }
  subscribeEvent(event) {
    if (this.socket) {
      this.socket.on(event, (msg) => {
        alert(`socket message(${event}): ` + JSON.stringify(msg));
      });
    }
  }
  subscribeEventWithStore(event, storeMutation = "socket/setMessage") {
    if (this.socket) {
      this.socket.on(event, (data) => {
        // alert(`socket message(${event}): ` + JSON.stringify(msg));
        store.commit(storeMutation, { event, data });
      });
    }
  }
  subscribeEventWithFunction(event, callBackFn) {
    if (this.socket) {
      this.socket.on(event, (data) => {
        callBackFn(data);
      });
    }
  }
  unsubscribeEventWithFunction(event, callBackFn) {
    if (this.socket) {
      this.socket.off(event, callBackFn);
    }
  }
}
